<template>
    <user-list :header="'Usuarios'" :type="''" :userList="users.filter(n => n.nomUsu)"/>
</template>

<script>
  import UserList from '@/components/UserList'
  export default {
    name: 'Index',
    components: { UserList },
    data() {
      return {
        users: []
      }
    },
    async created() {
      this.users = (await this.$axios.post('Users/users')).data;
    }
  }
</script>
